import axios from 'axios'
import { noty } from '../services/notyService'

/**
 * Create an Axios Client with defaults
 */
const client = axios.create({})

/**
 * Request Wrapper with default success/error actions
 */
const request = function (options) {
  options = {
    // headers: {
    //   Authorization: localStorage.getItem('pyUser'),
    //   'Content-Type': 'text/xml',
    // },
    ...options,
  }

  const onSuccess = function (response) {
    return response.data ? response.data : response
  }

  const onError = function (error) {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          noty('error', `Authentication error, Please re-login and try again`)
          break
        case 404:
          noty('error', `Please try again`)
          break
        default:
          noty('error', `Server Error, Contact Administrator`)
          break
      }
    } else {
      noty('error', error.message)
    }

    return Promise.reject(error.response || error.message)
  }

  return client(options).then(onSuccess).catch(onError)
}

export default request
