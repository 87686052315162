import React from 'react'
import moment from 'moment'
import { Typography, Paper, Box, Grid, Button, Divider } from '@mui/material'
import { noty } from '../../services/notyService'
import { useSelector, useDispatch } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'
import { LoginAction } from '../../Actions/LoginAction'
import { LoadingButton } from '@mui/lab'

let slotsPaper = {
  minHeight: '430px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  // marginLeft:'20px',
}

function Slots({
  value,
  timeSlot,
  setTimeslot,
  isFinalScreenSubmited,
  CaseID,
  timezone,
  Reschedule,
  finalTimeToShow,
  setFinalTimeToShow,
}) {
  const getSlotsLoader =
    useSelector((state) => state.LoginReducer.getSlotsLoader) || false

  const submitSlotsLoader =
    useSelector((state) => state.LoginReducer.submitSlotsLoader) || false

  const DisplaySlots =
    useSelector((state) => state.LoginReducer.DisplaySlots) || []

  const dispatch = useDispatch()

  return (
    <Box>
      <React.Fragment>
        {!isFinalScreenSubmited ? (
          <Paper style={slotsPaper} elevation={3}>
            <div>
              <Box m={2} mb={1}>
                <Typography variant="subtitle1">
                  Available Slots on{' '}
                  <strong>{moment(value).format('LL')}</strong>
                </Typography>
              </Box>
              <Box m={2} mt={0}>
                <Divider />
              </Box>

              <Box m={1} p={1}>
                {getSlotsLoader ? (
                  <Box>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Grid container spacing={2}>
                    {DisplaySlots.map((item, index) => {
                      let time = item.AvailableSlot1.split('.')

                      let hrs = time[0].length === 1 ? `0${time[0]}` : time[0]
                      let min = time[1] ? time[1] : '00'

                      let convertedHrs = `${moment(new Date()).format(
                        'YYYY-MM-DD',
                      )}T${hrs}:${min}:00`
                      return (
                        <React.Fragment key={index}>
                          <Grid item key={index} xs={6} md={3}>
                            <Button
                              size={'small'}
                              onClick={() => {
                                setTimeslot(item.AvailableSlot1)
                                setFinalTimeToShow(
                                  moment(convertedHrs).format('hh:mm a')
                                )
                              }}
                              color="primary"
                              variant={
                                item.AvailableSlot1 === timeSlot
                                  ? 'contained'
                                  : 'outlined'
                              }
                            >
                              {moment(convertedHrs).format('hh:mm a')}
                            </Button>
                          </Grid>
                        </React.Fragment>
                      )
                    })}
                  </Grid>
                )}
              </Box>
            </div>
            {!getSlotsLoader && (
              <Box
                m={2}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <LoadingButton
                  disabled={submitSlotsLoader}
                  color="primary"
                  onClick={() => {
                    if (timeSlot && timezone) {
                      let finalTimeSlot = timeSlot.split('.')
                      let updatedHrs =
                        finalTimeSlot[0].length === 1
                          ? `0${finalTimeSlot[0]}`
                          : finalTimeSlot[0]

                      dispatch(LoginAction.submitSlotsLoader())
                      dispatch(
                        LoginAction.submitSlots({
                          pyID: CaseID,
                          Timezone: timezone,
                          Reschedule: Reschedule,
                          actionType: 'SubmitSlot',
                          Weekday: moment(value).format('dddd'),
                          Date: moment(value).format('YYYY/MM/DD'),
                          ApplicantSlotStartTime:
                            finalTimeSlot.length === 1
                              ? `${updatedHrs}.00`
                              : `${updatedHrs}.${finalTimeSlot[1]}`,
                        }),
                      )
                    } else {
                      let errorOn = 'Timezone and Slot'

                      if (!timezone && !timeSlot) {
                        errorOn = 'Timezone and Slot'
                      } else if (!timeSlot) {
                        errorOn = 'Slot'
                      } else if (!timezone) {
                        errorOn = 'Timezone'
                      }

                      noty('error', `Please select ${errorOn} `)
                    }
                  }}
                  loading={submitSlotsLoader}
                  variant="contained"
                >
                  schedule interview
                </LoadingButton>
              </Box>
            )}
          </Paper>
        ) : (
          <Paper
            style={{
              display: 'flex',
              height: '70vh',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <i
              style={{
                display: 'flex',
                alignSelf: 'center',
                alignItems: 'center',
                padding: '20px',
                fontWeight: 300,
                lineHeight: '40px',
              }}
            >
              <span style={{ fontSize: '20px' }}>
                Thank you for confirming your availability on date, at time
                &nbsp;{' '}
                <Typography
                  color="primary"
                  variant="h6"
                  style={{ display: 'inline-block' }}
                >
                  {`${moment(value).format('ll')} , ${finalTimeToShow}`}
                </Typography>{' '}
                &nbsp; A separate interview invite will be shared with you via
                email. Please accept the meeting to confirm your participation.
              </span>
            </i>
          </Paper>
        )}
      </React.Fragment>
    </Box>
  )
}

export default Slots
